/**
 * Find one object in an array by specific key.
 * @param {Object[]} array
 * @param {string} key
 * @param {*} value
 */
export function findBy(array, key, value) {
  // return array.find(item => item[key] === value);
  return array.find(item => item[key] == value);
}

/**
 * Find one object's index in an array by specific key.
 * @param {Object[]} array
 * @param {string} key
 * @param {*} value
 */
export function findIndexBy(array, key, value) {
  return array.findIndex(item => item[key] === value);
}

/**
 * Group array of objects by specific key.
 * @param {any[]} array
 * @param {string} key
 * @return {Map}
 */
export function groupBy(array, key) {
  const map = new Map();

  array.forEach(object => {
    if (map.has(object[key])) {
      const group = map.get(object[key]);
      group.push(object);
    } else {
      map.set(object[key], [object]);
    }
  });

  return map;
}

export function getDynamicPath(prefix, id, name = '') {
  return prefix && id
    ? `/${prefix}/${id}${name && '/' + encodeURIComponent(name)}`
    : '#';
}

export function calculateDateNextPeriod(period, date = new Date()) {
  let time = date.getTime();
  for (let i = 0; i < period; i++) {
    let days = 30;
    switch (date.getMonth() + 1) {
      case 2:
        days = 28;
        break;
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        days = 31;
        break;
      default:
        days = 30;
        break;
    }
    time += days * 86400000;
    date = new Date(time);
  }
  return new Date(time);
}

function addMonths(date, months) {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() != d) {
    date.setDate(0);
  }
  return date;
}

export function getOrderSubscribePeriodDates(item) {
  const periodDates = [];

  const timeLastCreated = new Date(item.dateNextPeriod);
  var dayOrigin = timeLastCreated.getDate();

  var monthLastCreated = timeLastCreated.getMonth() + 1;
  var yearLastCreated = timeLastCreated.getFullYear();

  var gg = yearLastCreated * 12 + monthLastCreated;

  for (var i = 0; i <= 12; i++) {
    const now = new Date();

    let day = dayOrigin;

    var month = addMonths(new Date(), i).getMonth() + 1;
    var year = addMonths(new Date(), i).getFullYear();

    var ggg = year * 12 + month;

    let isActive = false;
    if ((ggg - gg) % item.period == 0 && i != 0) {
      isActive = true;
    }
    if (dayOrigin == 29) {
      switch (month) {
        case 2:
          day = 28;
          break;
      }
    } else {
      if (dayOrigin >= 30) {
        switch (month) {
          case 2:
            day = 28;
            break;
          case 9:
          case 11:
          case 4:
          case 6:
            day = 30;
            break;
        }
      }
    }

    var date = new Date(year + '-' + month + '-' + day);
    var a = {
      isDot: false,
      month: addMonths(now, i).getMonth() + 1,
      day: day,
      i: i,
      isActive: isActive,
      ggg: ggg,
      date: date,
    };

    periodDates.push(a);
  }
  return periodDates;
}
